// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-staticpage-js": () => import("/opt/build/repo/src/templates/staticpage.js" /* webpackChunkName: "component---src-templates-staticpage-js" */),
  "component---src-templates-blogpost-js": () => import("/opt/build/repo/src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */),
  "component---src-templates-blogpost-redirect-js": () => import("/opt/build/repo/src/templates/blogpost_redirect.js" /* webpackChunkName: "component---src-templates-blogpost-redirect-js" */),
  "component---src-templates-categorylist-js": () => import("/opt/build/repo/src/templates/categorylist.js" /* webpackChunkName: "component---src-templates-categorylist-js" */),
  "component---src-templates-taglist-js": () => import("/opt/build/repo/src/templates/taglist.js" /* webpackChunkName: "component---src-templates-taglist-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

